<template>
  <div class="">
    Notificaciones
  </div>
</template>
<script>
export default {
  name: 'Notifications',
}
</script>
